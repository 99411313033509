// @ts-nocheck
import { Router } from '@gatsbyjs/reach-router';
import React from 'react';

import AppLayout from '../components/layout/AppLayout';
import { EditorMain } from '../components/old-version/EditorMain/EditorMain';

const Workspace = () => {
  return (
    <AppLayout>
      <Router default="/">
        <EditorMain path="/workspace/:workspaceId" />
      </Router>
    </AppLayout>
  );
};
export default Workspace;
